<template>
    <modal-lateral ref="modalRecompensas" titulo="Historial de recompensas diarias">
        <div class="custom-scroll overflow-auto pt-3" style="height:calc(100vh - 65px)">
            <div class="row mx-3">
                <div v-for="(hs, h) in historial_recompensas" :key="h" class="col-12 btn-gr-green px-3 mb-3">
                    <div class="col-auto mr-3 px-2 f-24 font-omnes text-white">
                        DÍA 6
                    </div>
                    <div class="col d-flex">
                        <template v-if="hs.tipo == 1">
                            <div class="space-coin text-center">
                                <img src="/img/gaming/i_moneda.svg" />
                                <span class="text-white f-600 px-3 font-omnes">
                                    + 45
                                </span>
                            </div>
                        </template>
                        <template v-if="hs.tipo == 2">
                            <img src="https://http2.mlstatic.com/D_NQ_NP_703051-MCO44296939957_122020-W.jpg" class="br-4 obj-cover" width="40" height="40" />
                            <div class="col-auto font-omnes f-17 text-white f-400">
                                Canguro NIKE
                            </div>
                        </template>
                        <template v-if="hs.tipo == 3">
                            <img src="/img/gaming/i_triste.png" class="br-4 obj-cover" width="50" height="50" />
                            <div class="col-auto pl-2 font-omnes f-17 text-white f-400 lh-19">
                                Sigue <br />
                                intentando
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
export default {
    data(){
        return {
            historial_recompensas: [
                {
                    dia: 'DÍA 7',
                    tipo: 1
                },
                {
                    dia: 'DÍA 6',
                    tipo: 2
                },
                {
                    dia: 'DÍA 5',
                    tipo: 3
                }
            ],
        }
    },
    methods: {
        toggle(){
            this.$refs.modalRecompensas.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-gr-green{
    height: 54px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    background: radial-gradient(circle at 162%, #B1FFE4,#80FFB7, #0DEC81);
    /* background-image: linear-gradient(to right, #0DEC81, #80FFB7, #B1FFE4); */
    .space-coin{
        max-width: 130px;
        height: 24px;
        border-radius: 4px;
        color: #FFFFFF;
        position: relative;
        background-color: #00C764;
        img{
            height: 33px;
            position: absolute;
            left: -15px;
            top: -5px;
        }
    }
    .space-cupon{
        max-width: 130px;
        height: 24px;
        border-radius: 4px;
        color: #FFFFFF;
        position: relative;
        background-color: #00C764;
        img{
            height: 33px;
            position: absolute;
            left: -15px;
            top: -5px;
        }
    }
}
</style>